import React from "react";
import Img from "gatsby-image";

class Thumbnail extends React.PureComponent {
  constructor(props) {
    super(props);

    this._img = React.createRef();

    this.state = {
      hover: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  tileViewportStyle() {
    if (this.props.tileViewportStyle)
      return this.props.tileViewportStyle.call(this);
    return {
      width: this.props.vWidth,
      height: this.props.height,
      overflow: "hidden"
    };
  }

  thumbnailStyle() {
    if (this.props.thumbnailStyle) return this.props.thumbnailStyle.call(this);

    return {
      cursor: "pointer",
      width: this.props.width,
      height: this.props.height,
      marginLeft: this.props.marginLeft,
      marginTop: 0
    };
  }

  handleClick(e) {
    const { onClick, index, originalImage } = this.props;

    if (onClick) {
      onClick(originalImage, index, e);
    }
  }

  render() {
    return (
      <div
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          margin: this.props.margin,
          display: "inline",
          userSelect: "none",
          position: "relative",
          background: "#eee",
          padding: "0px"
        }}
      >
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            height: "100%",
            width: "100%",
            pointerEvents: "none",
            opacity: 1,
            zIndex: 9999,
            background: this.state.hover
              ? "linear-gradient(to bottom,rgba(0,0,0,0.26),transparent 56px,transparent)"
              : "none"
          }}
        />
        <div style={this.tileViewportStyle()} onClick={this.handleClick}>
          <Img
            ref={this._img}
            style={this.thumbnailStyle()}
            fluid={this.props.fluid}
          />
        </div>
      </div>
    );
  }
}

export default Thumbnail;
