import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import FsLightbox from "fslightbox-react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";

import { graphql } from "gatsby";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import GalleryGrid from "../components/GalleryGrid";
import Heading from "../components/Heading";
import * as luxon from "luxon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SectionName = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 14px;
`;

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export default class GaleriaPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxToggler: false,
      imageIndex: 0
    };

    this.viewImage = this.viewImage.bind(this);
  }

  viewImage(image) {
    this.setState({
      lightboxToggler: !this.state.lightboxToggler,
      imageIndex: image.index || 0
    });
  }

  renderSection(key, name, images) {
    return (
      <Section key={key}>
        <SectionName>{name}</SectionName>
        <GalleryGrid images={images} onClickThumbnail={this.viewImage} />
      </Section>
    );
  }

  render() {
    const { data } = this.props;

    const images =
      data &&
      data.images &&
      data.images.edges &&
      data.images.edges
        .map((edge, index) => {
          const parts = edge.node.relativePath.split("/");
          const date = parts[0];
          const section = parts[parts.length - 2].replace(/_/g, " ");

          return {
            index,
            date,
            section,
            ...edge.node.childImageSharp
          };
        })
        .sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

    const sections = groupBy(images, "section");

    return (
      <Layout>
        <Seo title="Galería" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Content>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Galería</Heading>
                  {Object.keys(sections).map(key => {
                    const images = sections[key];
                    let name = key;
                    if (dateRegex.test(name)) {
                      name = luxon.DateTime.fromISO(name)
                        .setLocale("es")
                        .toFormat("DD");
                    }
                    return this.renderSection(key, name, images);
                  })}
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
          <FsLightbox
            toggler={this.state.lightboxToggler}
            sources={images.map(image => image.fluid.src)}
            slide={this.state.imageIndex + 1}
          />
        </Container>
      </Layout>
    );
  }
}

const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const query = graphql`
  query {
    images: allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`;
