import React from "react";
import GalleryImage from "./GalleryThumbnail";

export default class Grid extends React.PureComponent {
  static defaultProps = {
    rowHeight: 180,
    margin: 2
  };

  constructor(props) {
    super(props);

    this.state = {
      galleryWidth: 0
    };

    this._gallery = React.createRef();
  }

  componentDidMount() {
    this.onResizeGallery();
  }

  onResizeGallery() {
    if (!this._gallery.current) return;

    this.setState({
      galleryWidth: Math.floor(this._gallery.current.clientWidth)
    });
  }

  buildImageRow(thumbnails, galleryWidth) {
    const row = [];
    let len = 0;
    const imgMargin = 2 * this.props.margin;
    while (thumbnails.length > 0 && len < galleryWidth) {
      let thumb = thumbnails.shift();
      if (!thumb) {
        continue;
      }
      row.push(thumb);
      len += thumb.width + imgMargin;
    }

    const delta = len - galleryWidth;
    if (row.length > 0 && delta > 0) {
      const cutoff = calculateCutOff(len, delta, row);
      for (let i in row) {
        const pixelsToRemove = cutoff[i];
        let thumb = row[i];
        thumb.marginLeft = -Math.abs(Math.floor(pixelsToRemove / 2));
        thumb.vWidth = thumb.width - pixelsToRemove;
      }
    }

    return row;
  }

  createThumbnail(image) {
    const width = Math.floor(this.props.rowHeight * image.fluid.aspectRatio);

    return {
      originalImage: image,
      fluid: image.fluid,
      width,
      height: this.props.rowHeight,
      marginLeft: 0,
      vWidth: width
    };
  }

  calculateThumbnails(images, galleryWidth = this.state.galleryWidth) {
    if (!images) return [];
    if (galleryWidth === 0) return [];

    const thumbnails = images.map(img => this.createThumbnail(img));

    const thumbs = [];
    const rows = [];
    while (thumbnails.length > 0) {
      rows.push(this.buildImageRow(thumbnails, galleryWidth));
    }

    for (let r = 0; r < rows.length; r++) {
      let row = rows[r];
      for (let i = 0; i < row.length; i++) {
        const thumb = row[i];
        if (this.props.maxRows) {
          if (r < this.props.maxRows) {
            thumbs.push(thumb);
          }
        } else {
          thumbs.push(thumb);
        }
      }
    }

    return thumbs;
  }

  render() {
    const { images } = this.props;

    if (!images || !images.length) {
      return;
    }

    const thumbnails = this.calculateThumbnails(images);

    return (
      <div
        ref={this._gallery}
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <iframe
          title={"grid-resizer"}
          style={{
            height: 0,
            margin: 0,
            padding: 0,
            borderWidth: 0,
            overflow: "hidden",
            backgroundColor: "transparent",
            width: "100%"
          }}
          ref={c =>
            c &&
            c.contentWindow &&
            c.contentWindow.addEventListener(
              "resize",
              debounce(() => this.onResizeGallery(), 30, false)
            )
          }
        />
        {thumbnails.map((thumbnail, idx) => {
          return (
            <GalleryImage
              key={"Thumbnail-" + idx + "-" + thumbnail.src}
              index={idx}
              margin={this.props.margin}
              {...thumbnail}
              onClick={this.props.onClickThumbnail || null}
              tileViewportStyle={this.props.tileViewportStyle}
              thumbnailStyle={this.props.thumbnailStyle}
            />
          );
        })}
      </div>
    );
  }
}

function calculateCutOff(len, delta, thumbnails) {
  const cutoff = [];
  let cutsum = 0;
  for (let i in thumbnails) {
    const thumb = thumbnails[i];
    const fractOfLen = thumb.width / len;
    cutoff[i] = Math.floor(fractOfLen * delta);
    cutsum += cutoff[i];
  }

  let stillToCutOff = delta - cutsum;
  while (stillToCutOff > 0) {
    for (let i in cutoff) {
      cutoff[i]++;
      stillToCutOff--;
      if (stillToCutOff < 0) break;
    }
  }
  return cutoff;
}

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this;
    let args = arguments;
    let later = function() {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
